import * as React from 'react'
import { Helmet } from 'react-helmet'

const Seo = ({ wpSeo }) => {
  const { title } = wpSeo

  // const schema = {
  //   '@context': 'https://schema.org',
  //   '@graph': [
  //     {
  //       '@type': 'WebSite',
  //       '@id': 'https://gwg.kcm.agency/#website',
  //       url: 'https://gwg.kcm.agency/',
  //       name: 'Glentworth Group',
  //       description: 'Just another WordPress site',
  //       potentialAction: [
  //         {
  //           '@type': 'SearchAction',
  //           target: {
  //             '@type': 'EntryPoint',
  //             urlTemplate: 'https://gwg.kcm.agency/?s={search_term_string}',
  //           },
  //           'query-input': 'required name=search_term_string',
  //         },
  //       ],
  //       inLanguage: 'en-US',
  //     },
  //     {
  //       '@type': 'ImageObject',
  //       '@id': 'https://gwg.kcm.agency/planning-project-controls/#primaryimage',
  //       inLanguage: 'en-US',
  //       url: 'https://gwg.kcm.agency/media/Planning-Project-Controls.jpg',
  //       contentUrl:
  //         'https://gwg.kcm.agency/media/Planning-Project-Controls.jpg',
  //       width: 1920,
  //       height: 1280,
  //     },
  //     {
  //       '@type': 'WebPage',
  //       '@id': 'https://gwg.kcm.agency/planning-project-controls/#webpage',
  //       url: 'https://gwg.kcm.agency/planning-project-controls/',
  //       name: 'Planning & Project Controls - Glentworth Group',
  //       isPartOf: { '@id': 'https://gwg.kcm.agency/#website' },
  //       primaryImageOfPage: {
  //         '@id':
  //           'https://gwg.kcm.agency/planning-project-controls/#primaryimage',
  //       },
  //       datePublished: '2022-04-12T17:43:57+00:00',
  //       dateModified: '2022-04-27T11:50:24+00:00',
  //       breadcrumb: {
  //         '@id': 'https://gwg.kcm.agency/planning-project-controls/#breadcrumb',
  //       },
  //       inLanguage: 'en-US',
  //       potentialAction: [
  //         {
  //           '@type': 'ReadAction',
  //           target: ['https://gwg.kcm.agency/planning-project-controls/'],
  //         },
  //       ],
  //     },
  //     {
  //       '@type': 'BreadcrumbList',
  //       '@id': 'https://gwg.kcm.agency/planning-project-controls/#breadcrumb',
  //       itemListElement: [
  //         {
  //           '@type': 'ListItem',
  //           position: 1,
  //           name: 'Home',
  //           item: 'https://gwg.kcm.agency/',
  //         },
  //         {
  //           '@type': 'ListItem',
  //           position: 2,
  //           name: 'Planning &#038; Project Controls',
  //         },
  //       ],
  //     },
  //   ],
  // }

  return (
    <Helmet>
      <meta charSet="utf-8" />
      {title && <title>{title}</title>}
      {/*<meta name="robots" content="noindex, nofollow" />*/}
      {/*<meta property="og:locale" content="en_US" />*/}
      {/*<meta property="og:type" content="article" />*/}
      {/*<meta property="og:title" content={title} />*/}
      {/*<meta*/}
      {/*  property="og:url"*/}
      {/*  content="https://gwg.kcm.agency/planning-project-controls/"*/}
      {/*/>*/}
      {/*<meta property="og:site_name" content="Glentworth Group" />*/}
      {/*<meta*/}
      {/*  property="article:modified_time"*/}
      {/*  content="2022-04-27T11:50:24+00:00"*/}
      {/*/>*/}
      {/*<meta*/}
      {/*  property="og:image"*/}
      {/*  content="https://gwg.kcm.agency/media/Planning-Project-Controls.jpg"*/}
      {/*/>*/}
      {/*<meta property="og:image:width" content="1920" />*/}
      {/*<meta property="og:image:height" content="1280" />*/}
      {/*<meta property="og:image:type" content="image/jpeg" />*/}
      {/*<meta name="twitter:card" content="summary_large_image" />*/}
      {/*<script type="application/ld+json" className="yoast-schema-graph">*/}
      {/*  {JSON.stringify(schema)}*/}
      {/*</script>*/}
    </Helmet>
  )
}

export default Seo
